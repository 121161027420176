<template>
    <div v-if="isViewNotifications">
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <feather-icon v-b-modal.push-notifiation-rules height="20px" width="20px" icon="InfoIcon" />
                    </b-col>
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" @input="fetchNotifications" class="d-inline-block mr-1"
                                placeholder="Search..." />
                            <AddNotifications :manage="isAddNotifications" :token="bearerToken"
                                @fetchNotifications="fetchNotifications" :notificationTypes="notification_type" />
                            <b-button variant="primary" class="ml-1" :disabled="isResetBtn" v-if="isResetNotificationDate" @click="resetPushNotifications">
                                <span class="text-nowrap">Reset Push Notifications</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <Notification :show="show" :pushNotification="pushNotification.data || []" :manage="isAddNotifications"
                :token="bearerToken" @fetchNotifications="fetchNotifications" :notificationTypes="notification_type" />
            <div class="mx-2 mt-1 mb-1">
                <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                    <div class="dataTables_info mt-1"></div>
                    <pagination class="mt-1 mb-0" :data="pushNotification" :limit=2
                        @pagination-change-page="fetchNotifications">
                    </pagination>
                </div>
            </div>
        </b-card>
        <b-modal id="push-notifiation-rules" hide-footer size="md" centered title="Push Notification Rules">
            <div>1. <code>&lt;rank&gt;</code> -> Profile Rank</div>
            <div>2. <code>&lt;profileName&gt;</code> -> Profile Name</div>
            <div>3. <code>&lt;profileId&gt;</code> -> Profile Id</div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBreadcrumb,
    BModal,
    BButton
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        Notification: () => import("@/views/components/notifications/Notification.vue"),
        AddNotifications: () => import("@/views/components/notifications/AddNotification.vue"),
        ToastificationContent,
        BBreadcrumb,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BModal,
        BButton
    },
    data() {
        return {
            isAddNotifications: false,
            searchQuery: "",
            show: false,
            alertMessageToast: "",
            bearerToken: "",
            pushNotification: [],
            isViewNotifications: false,
            notification_type: [],
            isResetNotificationDate: false,
            isResetBtn: false,
        };
    },
    mounted() {
        this.isAddNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "add_app_push_notifications");
        this.isViewNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "view_app_push_notifications");
        this.isResetNotificationDate = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "profile_reset_notificaiton_date");
        this.bearerToken = "Bearer " + localStorage.getItem("_t")
        this.fetchNotifications();
        this.fetchNotificationTypes();
    },
    methods: {
        async fetchNotifications(page = 1) {
            this.show = true;
            const payload = {};
            payload.search = this.searchQuery;
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + `/push-notifications?page=${page}`, payload, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.pushNotification = res.data.notifications;
                this.show = false;
            } catch (error) {

            }
        },


        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },

        async resetPushNotifications() {
            this.isResetBtn = true;
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_API_URL}/profile/reset/notifications`,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                if (res.data.status == 200) {
                    this.alertMessageToast = res.data.msg;
                    this.showToast('success');
                }
            } catch (error) {
                this.alertMessageToast = 'Something went wrong try again later';
                this.showToast('danger');
            } finally {
                this.isResetBtn = false;
            }
        },

        async fetchNotificationTypes(page = 1) {
            this.show = true;

            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_API_URL}/notification-types?page=${page}`,
                    { search: this.searchQuery },
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.notification_type = res.data.notification_types.data;
            } catch (error) {
                console.error("Error fetching notification types:", error);
            } finally {
                this.show = false;
            }
        },
    },
};
</script>
